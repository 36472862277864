import React from 'react';
import { connect } from 'react-redux';
import { closePopup, createUserPopupPreview } from '../../actions/popup';
import { BASE_ZINDEX } from '../../popup-factory';
import { Toggle, ToggleLink, Box, Textarea, Button, Spinner, Row, Col } from '@commonsku/styles';

const userPopupPreviewAction = (company_type) => dispatch => {
    return dispatch(createUserPopupPreview(company_type));
};

export const UserPopupSettings = ({settings, loading, updateSetting, setting_name, onUserPopupPreview}) => {

    function _setting_name(v) {
        return v.company_type + '-' + setting_name;
    }

    return (
        loading ? <Spinner /> :
            <Row>
                <Col padded xs>
                    {settings && settings.data && settings.data.length > 0 && settings.data.map((v, i) => (
                        <Box key={'user_popup_card_'+i}
                            padded
                            title={v.company_type + " Popup"}
                            controls={<Button secondary size={'small'} onClick={() => { onUserPopupPreview(v.company_type); }}>Preview</Button>}
                        >
                            <Textarea
                                placeholder="Enter HTML"
                                value={v.content}
                                onChange={(e) => {
                                    updateSetting(_setting_name(v), {
                                        active: v.active,
                                        content: e.target.value,
                                        update_remote: false,
                                    });
                                }}
                                onBlur={(e) => {
                                    updateSetting(_setting_name(v), {
                                        active: v.active,
                                        content: e.target.value,
                                    });
                                }}
                                rows={20}
                            />
                            <Toggle style={{width: 110}} stretch={false}>
                                <ToggleLink
                                    selected={v.active == 1}
                                    onClick={() => {
                                        updateSetting(_setting_name(v), { active: 1, content: v.content });
                                    }}
                                >Active</ToggleLink>
                                <ToggleLink
                                    selected={v.active != 1}
                                    onClick={() => {
                                        updateSetting(_setting_name(v), { active: 0, content: v.content });
                                    }}
                                >Disabled</ToggleLink>
                            </Toggle>
                        </Box>
                    ))}
                </Col>
            </Row>
    );
};

const mapSettingStateToProps = (state, ownProps) => ({});
const mapSettingDispatchToProps = (dispatch, ownProps) => ({
    onUserPopupPreview: (company_type) => dispatch(userPopupPreviewAction(company_type)),
});
export const ConnectedUserPopupSettings = connect(mapSettingStateToProps, mapSettingDispatchToProps)(UserPopupSettings);

const UserPopupPreview = ({index=0, onClosePopup, company_type}) => {
    return (
        <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', maxHeight: '100%' }}>
            <div className="row">
                <div className="small-12 columns">
                    <Col padded>
                        <h3>{company_type} POPUP</h3>
                        <a className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={onClosePopup}>Close</a>
                    </Col>
                </div>
                <div className="small-12 columns">
                    <iframe id='user-popup'
                        title='User Popup'
                        style={{width: '100%', border: 0}}
                        src={"https://" + window.location.hostname + "/" + company_type.toLowerCase() + "_user_popup.php"}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = (dispatch, ownProps) => ({
    onClosePopup: () => dispatch(closePopup()),
});
const ConnectedUserPopupPreview = connect(mapStateToProps, mapDispatchToProps)(UserPopupPreview);
export default ConnectedUserPopupPreview;
